// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3rME9{height:20px;width:20px}.styles-module__Root___3rME9.styles-module__disabled___oZgqZ .styles-module__Path___5MgiR,.styles-module__Root___3rME9.styles-module__disabled___oZgqZ .styles-module__Polygon___2bU3m{cursor:not-allowed;fill:#bdbdbd}.styles-module__Path___5MgiR,.styles-module__Polygon___2bU3m{fill:#2071aa}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/icons/ImageUpload/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,UAAA,CACA,uLAEE,kBAAA,CACA,YAAA,CAEJ,6DAEE,YAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  height: 20px;\n  width: 20px; }\n  .Root.disabled .Path,\n  .Root.disabled .Polygon {\n    cursor: not-allowed;\n    fill: #bdbdbd; }\n\n.Path,\n.Polygon {\n  fill: #2071aa; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3rME9",
	"disabled": "styles-module__disabled___oZgqZ",
	"Path": "styles-module__Path___5MgiR",
	"Polygon": "styles-module__Polygon___2bU3m"
};
module.exports = exports;
