// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2IH_p{color:rgba(0,0,0,.65);cursor:pointer;font-size:24px;margin-top:13px}.styles-module__Root___2IH_p.styles-module__isActive___3yOqZ{color:#0a3959}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/navigation/SiteWideLinks/Desktop/ProfileIcon/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,qBAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACA,6DACE,aAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  color: rgba(0, 0, 0, 0.65);\n  cursor: pointer;\n  font-size: 24px;\n  margin-top: 13px; }\n  .Root.isActive {\n    color: #0a3959; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2IH_p",
	"isActive": "styles-module__isActive___3yOqZ"
};
module.exports = exports;
