// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3QUav{margin:0 auto;width:60px}.styles-module__Root___3QUav.styles-module__defaultSpacing___2Djii{margin:0 auto 12px}.styles-module__Root___3QUav div div{animation-duration:1.5s}.styles-module__Root___3QUav div div:nth-child(1){animation-delay:.24s}.styles-module__Root___3QUav div div:nth-child(2){animation-delay:.48s}.styles-module__Root___3QUav div div:nth-child(3){animation-delay:.72s}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/PulseLoadingIndicator/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,aAAA,CACA,UAAA,CACA,mEACE,kBAAA,CACF,qCACE,uBAAA,CACF,kDACE,oBAAA,CACF,kDACE,oBAAA,CACF,kDACE,oBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin: 0 auto;\n  width: 60px; }\n  .Root.defaultSpacing {\n    margin: 0 auto 12px; }\n  .Root div div {\n    animation-duration: 1.5s; }\n  .Root div div:nth-child(1) {\n    animation-delay: 0.24s; }\n  .Root div div:nth-child(2) {\n    animation-delay: 0.48s; }\n  .Root div div:nth-child(3) {\n    animation-delay: 0.72s; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3QUav",
	"defaultSpacing": "styles-module__defaultSpacing___2Djii"
};
module.exports = exports;
