// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (max-width: 568px){.styles-module__Modal___1I4r1{box-sizing:border-box;left:0;top:0;width:100%}}@media screen and (min-width: 568px){.styles-module__Modal___1I4r1{left:calc((100% - 468px)/2);min-width:420px}}.styles-module__ParagraphContent___3eJP8{margin-bottom:24px;text-align:left}.styles-module__ParagraphContent___3eJP8:first-of-type{margin-bottom:12px;margin-top:12px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/modals/Feedback/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,8BACE,qBAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CAAA,CAEJ,qCACE,8BACE,2BAAA,CACA,eAAA,CAAA,CAEJ,yCACE,kBAAA,CACA,eAAA,CACA,uDACE,kBAAA,CACA,eAAA","file":"styles.module.scss","sourcesContent":["@media screen and (max-width: 568px) {\n  .Modal {\n    box-sizing: border-box;\n    left: 0;\n    top: 0;\n    width: 100%; } }\n\n@media screen and (min-width: 568px) {\n  .Modal {\n    left: calc(calc(100% - 468px) / 2);\n    min-width: 420px; } }\n\n.ParagraphContent {\n  margin-bottom: 24px;\n  text-align: left; }\n  .ParagraphContent:first-of-type {\n    margin-bottom: 12px;\n    margin-top: 12px; }\n"]}]);
// Exports
exports.locals = {
	"Modal": "styles-module__Modal___1I4r1",
	"ParagraphContent": "styles-module__ParagraphContent___3eJP8"
};
module.exports = exports;
