// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3SLD3{margin-top:6px}.styles-module__Message___3pNLC{color:#c00}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/fields/FieldErrors/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAA,CAEF,gCACE,UAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin-top: 6px; }\n\n.Message {\n  color: #cc0000; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3SLD3",
	"Message": "styles-module__Message___3pNLC"
};
module.exports = exports;
