// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___D_6Q2 ul{list-style:disc outside none;list-style:initial}.styles-module__QuestionHeading___2gUtV{margin:24px 0 9px}@media screen and (max-width: 568px){.styles-module__QuestionHeading___2gUtV{margin-top:0}}.styles-module__AnswerHeading___zQeoe{margin:36px 0 0}.styles-module__StudySetHeading___3nja-{margin-top:36px}.styles-module__StudySetLink___16Rmb{margin-top:9px}.styles-module__StudySetLink___16Rmb a{color:#2071aa;text-decoration:none}.styles-module__OtherInfoHeading___24h4_{margin:36px 0 9px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/ShowQuestion/Preview/styles.module.scss"],"names":[],"mappings":"AAAA,gCACE,4BAAA,CAAA,kBAAA,CAEF,wCACE,iBAAA,CACA,qCACE,wCACE,YAAA,CAAA,CAEN,sCACE,eAAA,CAEF,wCACE,eAAA,CAEF,qCACE,cAAA,CACA,uCACE,aAAA,CACA,oBAAA,CAEJ,yCACE,iBAAA","file":"styles.module.scss","sourcesContent":[".Root ul {\n  list-style: initial; }\n\n.QuestionHeading {\n  margin: 24px 0 9px; }\n  @media screen and (max-width: 568px) {\n    .QuestionHeading {\n      margin-top: 0; } }\n\n.AnswerHeading {\n  margin: 36px 0 0; }\n\n.StudySetHeading {\n  margin-top: 36px; }\n\n.StudySetLink {\n  margin-top: 9px; }\n  .StudySetLink a {\n    color: #2071aa;\n    text-decoration: none; }\n\n.OtherInfoHeading {\n  margin: 36px 0 9px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___D_6Q2",
	"QuestionHeading": "styles-module__QuestionHeading___2gUtV",
	"AnswerHeading": "styles-module__AnswerHeading___zQeoe",
	"StudySetHeading": "styles-module__StudySetHeading___3nja-",
	"StudySetLink": "styles-module__StudySetLink___16Rmb",
	"OtherInfoHeading": "styles-module__OtherInfoHeading___24h4_"
};
module.exports = exports;
