// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__LinkContainer___3LFBP{display:flex;flex-direction:row;justify-content:flex-start;margin-bottom:18px}@media screen and (min-width: 568px){.styles-module__MobileContainer___3374y{display:none}}@media screen and (max-width: 567px){.styles-module__DesktopContainer___2LkIz{display:none}}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/publicStudySets/ShowQuestion/styles.module.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CAEF,qCACE,wCACE,YAAA,CAAA,CAEJ,qCACE,yCACE,YAAA,CAAA","file":"styles.module.scss","sourcesContent":[".LinkContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  margin-bottom: 18px; }\n\n@media screen and (min-width: 568px) {\n  .MobileContainer {\n    display: none; } }\n\n@media screen and (max-width: 567px) {\n  .DesktopContainer {\n    display: none; } }\n"]}]);
// Exports
exports.locals = {
	"LinkContainer": "styles-module__LinkContainer___3LFBP",
	"MobileContainer": "styles-module__MobileContainer___3374y",
	"DesktopContainer": "styles-module__DesktopContainer___2LkIz"
};
module.exports = exports;
