// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2ifoX{background:#bdbdbd;color:#bdbdbd;padding-top:1px;width:100%}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/HorizontalLine/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,aAAA,CACA,eAAA,CACA,UAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  background: #bdbdbd;\n  color: #bdbdbd;\n  padding-top: 1px;\n  width: 100%; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2ifoX"
};
module.exports = exports;
