// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__HorizontalLine___JUJCC{margin-bottom:26px;margin-top:18px}.styles-module__HorizontalLine___JUJCC div{margin-left:0;width:100%}.styles-module__SampleQuizLink___25h8i a{margin-bottom:9px;margin-top:0}div.styles-module__FlashStudySessionButton___5rhxY.styles-module__FlashStudySessionButtonExtraSpecificity___2Waym{width:auto;width:initial}div.styles-module__FlashStudySessionButton___5rhxY.styles-module__FlashStudySessionButtonExtraSpecificity___2Waym>div{background-color:rgba(0,0,0,0);color:#2071aa;text-decoration:none}div.styles-module__FlashStudySessionButton___5rhxY.styles-module__FlashStudySessionButtonExtraSpecificity___2Waym>div p{color:#2071aa;text-decoration:none}div.styles-module__FlashStudySessionButton___5rhxY.styles-module__FlashStudySessionButtonExtraSpecificity___2Waym>div i{color:#2071aa}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/studySets/Show/QuizOptions/styles.module.scss"],"names":[],"mappings":"AAAA,uCACE,kBAAA,CACA,eAAA,CACA,2CACE,aAAA,CACA,UAAA,CAEJ,yCACE,iBAAA,CACA,YAAA,CAEF,kHACE,UAAA,CAAA,aAAA,CACA,sHACE,8BAAA,CACA,aAAA,CACA,oBAAA,CACA,wHACE,aAAA,CACA,oBAAA,CACF,wHACE,aAAA","file":"styles.module.scss","sourcesContent":[".HorizontalLine {\n  margin-bottom: 26px;\n  margin-top: 18px; }\n  .HorizontalLine div {\n    margin-left: 0;\n    width: 100%; }\n\n.SampleQuizLink a {\n  margin-bottom: 9px;\n  margin-top: 0; }\n\ndiv.FlashStudySessionButton.FlashStudySessionButtonExtraSpecificity {\n  width: initial; }\n  div.FlashStudySessionButton.FlashStudySessionButtonExtraSpecificity > div {\n    background-color: transparent;\n    color: #2071aa;\n    text-decoration: none; }\n    div.FlashStudySessionButton.FlashStudySessionButtonExtraSpecificity > div p {\n      color: #2071aa;\n      text-decoration: none; }\n    div.FlashStudySessionButton.FlashStudySessionButtonExtraSpecificity > div i {\n      color: #2071aa; }\n"]}]);
// Exports
exports.locals = {
	"HorizontalLine": "styles-module__HorizontalLine___JUJCC",
	"SampleQuizLink": "styles-module__SampleQuizLink___25h8i",
	"FlashStudySessionButton": "styles-module__FlashStudySessionButton___5rhxY",
	"FlashStudySessionButtonExtraSpecificity": "styles-module__FlashStudySessionButtonExtraSpecificity___2Waym"
};
module.exports = exports;
