// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1cN_Q{box-sizing:border-box;cursor:pointer;min-height:52px;padding:16px 24px;position:relative;text-align:center;width:100%}.styles-module__Root___1cN_Q:hover,.styles-module__Root___1cN_Q:focus{background-color:#edf2f7}.styles-module__IsActiveBottomBar___3kWfe{background-color:#f4793e;bottom:0;height:2px;left:0;position:absolute;width:100%}.styles-module__Link___16QPO{text-decoration:none}.styles-module__Title___b6qvz{color:rgba(0,0,0,.65);font-size:16px;line-height:16px}.styles-module__Title___b6qvz.styles-module__isActive___zjOsw{color:#0a3959;font-weight:600}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/navigation/SiteWideLinks/Desktop/LinkItem/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,qBAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA,CACA,sEACE,wBAAA,CAEJ,0CACE,wBAAA,CACA,QAAA,CACA,UAAA,CACA,MAAA,CACA,iBAAA,CACA,UAAA,CAEF,6BACE,oBAAA,CAEF,8BACE,qBAAA,CACA,cAAA,CACA,gBAAA,CACA,8DACE,aAAA,CACA,eAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  box-sizing: border-box;\n  cursor: pointer;\n  min-height: 52px;\n  padding: 16px 24px;\n  position: relative;\n  text-align: center;\n  width: 100%; }\n  .Root:hover, .Root:focus {\n    background-color: #edf2f7; }\n\n.IsActiveBottomBar {\n  background-color: #f4793e;\n  bottom: 0;\n  height: 2px;\n  left: 0;\n  position: absolute;\n  width: 100%; }\n\n.Link {\n  text-decoration: none; }\n\n.Title {\n  color: rgba(0, 0, 0, 0.65);\n  font-size: 16px;\n  line-height: 16px; }\n  .Title.isActive {\n    color: #0a3959;\n    font-weight: 600; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1cN_Q",
	"IsActiveBottomBar": "styles-module__IsActiveBottomBar___3kWfe",
	"Link": "styles-module__Link___16QPO",
	"Title": "styles-module__Title___b6qvz",
	"isActive": "styles-module__isActive___zjOsw"
};
module.exports = exports;
