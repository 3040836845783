// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2W5u0{margin-top:18px}.styles-module__Root___2W5u0:nth-of-type(2){margin-top:12px}.styles-module__Link___15qIX{color:#2071aa;text-decoration:none}.styles-module__InfoIconSpan___39Uqs{margin-left:2px}.styles-module__InfoIconSpan___39Uqs svg{height:20px;margin:0 0 -6px 0;width:20px}.styles-module__InfoIconSpan___39Uqs svg path{fill:#262322}.styles-module__LetUsKnowLink___14DJu{color:#2071aa;cursor:pointer;text-decoration:none}.styles-module__LetUsKnowLink___14DJu:hover{cursor:pointer}.styles-module__LetUsKnowLink___14DJu *:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Dashboard/OpeningContent/ActionContent/OpeningPara/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CACA,4CACE,eAAA,CAEJ,6BACE,aAAA,CACA,oBAAA,CAEF,qCACE,eAAA,CACA,yCACE,WAAA,CACA,iBAAA,CACA,UAAA,CACA,8CACE,YAAA,CAEN,sCACE,aAAA,CACA,cAAA,CACA,oBAAA,CACA,4CACE,cAAA,CACF,8CACE,cAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin-top: 18px; }\n  .Root:nth-of-type(2) {\n    margin-top: 12px; }\n\n.Link {\n  color: #2071aa;\n  text-decoration: none; }\n\n.InfoIconSpan {\n  margin-left: 2px; }\n  .InfoIconSpan svg {\n    height: 20px;\n    margin: 0 0 -6px 0;\n    width: 20px; }\n    .InfoIconSpan svg path {\n      fill: #262322; }\n\n.LetUsKnowLink {\n  color: #2071aa;\n  cursor: pointer;\n  text-decoration: none; }\n  .LetUsKnowLink:hover {\n    cursor: pointer; }\n  .LetUsKnowLink *:hover {\n    cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2W5u0",
	"Link": "styles-module__Link___15qIX",
	"InfoIconSpan": "styles-module__InfoIconSpan___39Uqs",
	"LetUsKnowLink": "styles-module__LetUsKnowLink___14DJu"
};
module.exports = exports;
