// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1ddgB{background-color:#fff;border-bottom:1px solid #828282;line-height:24px;padding:2px 4px;text-align:center;width:40px}.styles-module__Root___1ddgB:focus{box-shadow:none;outline:none}.styles-module__Root___1ddgB.styles-module__isDisabled___DNIll{border-color:#bdbdbd;color:#bdbdbd}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;appearance:none;margin:0}input[type=number]{-webkit-appearance:textfield;-moz-appearance:textfield;appearance:textfield}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/fields/MinimalNumberField/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,qBAAA,CACA,+BAAA,CACA,gBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA,CACA,mCACE,eAAA,CACA,YAAA,CACF,+DACE,oBAAA,CACA,aAAA,CAEJ,kEAEE,uBAAA,CAAA,eAAA,CACA,QAAA,CAGF,mBACE,4BAAA,CAAA,yBAAA,CAAA,oBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  background-color: #ffffff;\n  border-bottom: 1px solid #828282;\n  line-height: 24px;\n  padding: 2px 4px;\n  text-align: center;\n  width: 40px; }\n  .Root:focus {\n    box-shadow: none;\n    outline: none; }\n  .Root.isDisabled {\n    border-color: #bdbdbd;\n    color: #bdbdbd; }\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  appearance: none;\n  margin: 0; }\n\n/* Firefox */\ninput[type=\"number\"] {\n  appearance: textfield; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1ddgB",
	"isDisabled": "styles-module__isDisabled___DNIll"
};
module.exports = exports;
