// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Logo___36Wfc{margin-top:0}.styles-module__Logo___36Wfc a>div{transform:scale(0.6);width:100px}.styles-module__Logo___36Wfc.styles-module__shouldAnimate___cNeAt{animation:styles-module__move___mdfMv 2s}@keyframes styles-module__move___mdfMv{0%{margin-top:41px}100%{margin-top:0}}@media screen and (min-width: 768px){@keyframes styles-module__move___mdfMv{0%{margin-top:60px}100%{margin-top:0}}}.styles-module__Logo___36Wfc.styles-module__shouldAnimate___cNeAt a>div{animation:styles-module__downsize___1Q914 2s}@keyframes styles-module__downsize___1Q914{0%{transform:scale(1)}100%{transform:scale(0.6)}}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/quiz/Congratulations/PostTransition/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,mCACE,oBAAA,CACA,WAAA,CACF,kEACE,wCAAA,CAEJ,uCACE,GACE,eAAA,CACF,KACE,YAAA,CAAA,CACA,qCACE,uCACE,GACE,eAAA,CACF,KACE,YAAA,CAAA,CAAA,CACR,wEACE,4CAAA,CAEJ,2CACE,GAEE,kBAAA,CACF,KAEE,oBAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Logo {\n  margin-top: 0; }\n  .Logo a > div {\n    transform: scale(0.6);\n    width: 100px; }\n  .Logo.shouldAnimate {\n    animation: move 2s; }\n\n@keyframes move {\n  0% {\n    margin-top: 41px; }\n  100% {\n    margin-top: 0; } }\n    @media screen and (min-width: 768px) {\n      @keyframes move {\n        0% {\n          margin-top: 60px; }\n        100% {\n          margin-top: 0; } } }\n  .Logo.shouldAnimate a > div {\n    animation: downsize 2s; }\n\n@keyframes downsize {\n  0% {\n    /* stylelint-disable-line selector-max-compound-selectors */\n    transform: scale(1); }\n  100% {\n    /* stylelint-disable-line selector-max-compound-selectors */\n    transform: scale(0.6); } }\n"]}]);
// Exports
exports.locals = {
	"Logo": "styles-module__Logo___36Wfc",
	"shouldAnimate": "styles-module__shouldAnimate___cNeAt",
	"move": "styles-module__move___mdfMv",
	"downsize": "styles-module__downsize___1Q914"
};
module.exports = exports;
