// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___14Owh{background-color:#f4faf9;height:100%;min-height:100vh;width:100%}.styles-module__Container___3fbyL{padding:16px 16px 103px}@media screen and (min-width: 768px){.styles-module__Container___3fbyL{margin:0 auto;padding:16px 0 103px;width:464px}}.styles-module__Container___3fbyL>div{max-width:100%}@media screen and (min-width: 768px){.styles-module__Container___3fbyL{min-width:730px}}@media screen and (min-width: 980px){.styles-module__Container___3fbyL{padding-top:118px}}.styles-module__PulseLoader___3iWz9{margin-top:96px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Dashboard/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,wBAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CAEF,kCACE,uBAAA,CACA,qCACE,kCACE,aAAA,CACA,oBAAA,CACA,WAAA,CAAA,CACJ,sCACE,cAAA,CACF,qCACE,kCACE,eAAA,CAAA,CACJ,qCACE,kCACE,iBAAA,CAAA,CAEN,oCACE,eAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  background-color: #f4faf9;\n  height: 100%;\n  min-height: 100vh;\n  width: 100%; }\n\n.Container {\n  padding: 16px 16px 103px; }\n  @media screen and (min-width: 768px) {\n    .Container {\n      margin: 0 auto;\n      padding: 16px 0 103px;\n      width: 464px; } }\n  .Container > div {\n    max-width: 100%; }\n  @media screen and (min-width: 768px) {\n    .Container {\n      min-width: 730px; } }\n  @media screen and (min-width: 980px) {\n    .Container {\n      padding-top: 118px; } }\n\n.PulseLoader {\n  margin-top: 96px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___14Owh",
	"Container": "styles-module__Container___3fbyL",
	"PulseLoader": "styles-module__PulseLoader___3iWz9"
};
module.exports = exports;
