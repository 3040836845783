// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__FormattedQuestionContent___2WPNF{text-align:center}.styles-module__RevealAnswerSubText____t0yO{font-style:italic;line-height:20px;margin-top:36px;text-align:center;width:100%}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/quiz/Question/QuestionContent/styles.module.scss"],"names":[],"mappings":"AAAA,iDACE,iBAAA,CAEF,4CACE,iBAAA,CACA,gBAAA,CACA,eAAA,CACA,iBAAA,CACA,UAAA","file":"styles.module.scss","sourcesContent":[".FormattedQuestionContent {\n  text-align: center; }\n\n.RevealAnswerSubText {\n  font-style: italic;\n  line-height: 20px;\n  margin-top: 36px;\n  text-align: center;\n  width: 100%; }\n"]}]);
// Exports
exports.locals = {
	"FormattedQuestionContent": "styles-module__FormattedQuestionContent___2WPNF",
	"RevealAnswerSubText": "styles-module__RevealAnswerSubText____t0yO"
};
module.exports = exports;
