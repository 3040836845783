// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___TMqE4{display:flex;flex-direction:column;justify-content:flex-start;grid-gap:16px;gap:16px;width:162px}.styles-module__Root___TMqE4:first-of-type{margin-left:-23px;width:162px}.styles-module__Circle___2RetH{display:flex;flex-direction:column;justify-content:center;border:3px solid #f4793e;border-radius:100%;color:#f4793e;font-size:48px;height:96px;margin:0 auto;text-align:center;width:96px}.styles-module__Para___1LT_e{font-weight:600;text-align:center}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/authentication/Welcome/InfoSection/CircleSection/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,aAAA,CAAA,QAAA,CACA,WAAA,CACA,2CACE,iBAAA,CACA,WAAA,CAEJ,+BACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,wBAAA,CACA,kBAAA,CACA,aAAA,CACA,cAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CACA,UAAA,CAEF,6BACE,eAAA,CACA,iBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  gap: 16px;\n  width: 162px; }\n  .Root:first-of-type {\n    margin-left: -23px;\n    width: 162px; }\n\n.Circle {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  border: 3px solid #f4793e;\n  border-radius: 100%;\n  color: #f4793e;\n  font-size: 48px;\n  height: 96px;\n  margin: 0 auto;\n  text-align: center;\n  width: 96px; }\n\n.Para {\n  font-weight: 600;\n  text-align: center; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___TMqE4",
	"Circle": "styles-module__Circle___2RetH",
	"Para": "styles-module__Para___1LT_e"
};
module.exports = exports;
