// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Container___1BFHX{background:#f4793e;border-radius:0 6px 6px 0;box-sizing:border-box;cursor:pointer;max-width:30px;padding:7px;position:fixed;right:0;top:calc(50vh - 32px);transform:rotate(-180deg)}.styles-module__Para___3-TXT{color:#fff;line-height:16px;-ms-writing-mode:tb-rl;writing-mode:vertical-rl}.styles-module__Icon____ZBVR{fill:#fff;margin:-13px -1px 4px 0;transform:rotate(-180deg)}.styles-module__Icon____ZBVR svg{height:20px;width:20px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/FeedbackWidget/styles.module.scss"],"names":[],"mappings":"AAAA,kCACE,kBAAA,CACA,yBAAA,CACA,qBAAA,CACA,cAAA,CACA,cAAA,CACA,WAAA,CACA,cAAA,CACA,OAAA,CACA,qBAAA,CACA,yBAAA,CAEF,6BACE,UAAA,CACA,gBAAA,CACA,sBAAA,CAAA,wBAAA,CAEF,6BACE,SAAA,CACA,uBAAA,CACA,yBAAA,CACA,iCACE,WAAA,CACA,UAAA","file":"styles.module.scss","sourcesContent":[".Container {\n  background: #f4793e;\n  border-radius: 0 6px 6px 0;\n  box-sizing: border-box;\n  cursor: pointer;\n  max-width: 30px;\n  padding: 7px;\n  position: fixed;\n  right: 0;\n  top: calc(50vh - 32px);\n  transform: rotate(-180deg); }\n\n.Para {\n  color: #ffffff;\n  line-height: 16px;\n  writing-mode: vertical-rl; }\n\n.Icon {\n  fill: #ffffff;\n  margin: -13px -1px 4px 0;\n  transform: rotate(-180deg); }\n  .Icon svg {\n    height: 20px;\n    width: 20px; }\n"]}]);
// Exports
exports.locals = {
	"Container": "styles-module__Container___1BFHX",
	"Para": "styles-module__Para___3-TXT",
	"Icon": "styles-module__Icon____ZBVR"
};
module.exports = exports;
