// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2Z6mY{padding:16px 20px 20px}.styles-module__Toggle___2_RJ5{width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Stats/Charts/AnswersMemorized/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,sBAAA,CAEF,+BACE,sBAAA,CAAA,iBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  padding: 16px 20px 20px; }\n\n.Toggle {\n  width: fit-content; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2Z6mY",
	"Toggle": "styles-module__Toggle___2_RJ5"
};
module.exports = exports;
