// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___kd1jF{margin:0 0 45px;padding-bottom:24px}@media screen and (min-width: 568px){.styles-module__Root___kd1jF{padding-bottom:0}}.styles-module__ButtonContainer___993CR{margin-top:45px}.styles-module__LineSeparator___2sKOd{background-color:#bdbdbd;height:1px;margin:45px auto 0;width:60px}.styles-module__EvaluationExplanation___27Chp{margin-top:45px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/quiz/Question/RecallEvaluation/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CACA,mBAAA,CACA,qCACE,6BACE,gBAAA,CAAA,CAEN,wCACE,eAAA,CAEF,sCACE,wBAAA,CACA,UAAA,CACA,kBAAA,CACA,UAAA,CAEF,8CACE,eAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin: 0 0 45px;\n  padding-bottom: 24px; }\n  @media screen and (min-width: 568px) {\n    .Root {\n      padding-bottom: 0; } }\n\n.ButtonContainer {\n  margin-top: 45px; }\n\n.LineSeparator {\n  background-color: #bdbdbd;\n  height: 1px;\n  margin: 45px auto 0;\n  width: 60px; }\n\n.EvaluationExplanation {\n  margin-top: 45px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___kd1jF",
	"ButtonContainer": "styles-module__ButtonContainer___993CR",
	"LineSeparator": "styles-module__LineSeparator___2sKOd",
	"EvaluationExplanation": "styles-module__EvaluationExplanation___27Chp"
};
module.exports = exports;
