// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (max-width: 567px){.styles-module__Root___1376E{padding:79px 16px 16px;background-color:#fff;box-sizing:border-box;height:100%;width:100%;min-height:100vh}}@media screen and (max-width: 567px)and (min-width: 768px){.styles-module__Root___1376E{margin:0 auto;padding:79px 0 16px;width:464px}}@media screen and (min-width: 568px)and (max-width: 768px){.styles-module__Root___1376E{margin:12px auto 0;width:464px}}@media screen and (min-width: 568px){.styles-module__MobileContainer___39mcq{display:none}}@media screen and (max-width: 567px){.styles-module__DesktopContainer___2qv2n{display:none}}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/quiz/Explanation/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,6BACE,sBAAA,CACA,qBAAA,CACA,qBAAA,CACA,WAAA,CACA,UAAA,CACA,gBAAA,CAAA,CACF,2DACE,6BACE,aAAA,CACA,mBAAA,CACA,WAAA,CAAA,CAEN,2DACE,6BACE,kBAAA,CACA,WAAA,CAAA,CAEJ,qCACE,wCACE,YAAA,CAAA,CAEJ,qCACE,yCACE,YAAA,CAAA","file":"styles.module.scss","sourcesContent":["@media screen and (max-width: 567px) {\n  .Root {\n    padding: 79px 16px 16px;\n    background-color: #ffffff;\n    box-sizing: border-box;\n    height: 100%;\n    width: 100%;\n    min-height: 100vh; } }\n  @media screen and (max-width: 567px) and (min-width: 768px) {\n    .Root {\n      margin: 0 auto;\n      padding: 79px 0 16px;\n      width: 464px; } }\n\n@media screen and (min-width: 568px) and (max-width: 768px) {\n  .Root {\n    margin: 12px auto 0;\n    width: 464px; } }\n\n@media screen and (min-width: 568px) {\n  .MobileContainer {\n    display: none; } }\n\n@media screen and (max-width: 567px) {\n  .DesktopContainer {\n    display: none; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1376E",
	"MobileContainer": "styles-module__MobileContainer___39mcq",
	"DesktopContainer": "styles-module__DesktopContainer___2qv2n"
};
module.exports = exports;
