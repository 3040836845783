// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2_BW2{margin-top:18px}.styles-module__Root___2_BW2 p{float:none;float:initial}.styles-module__LeverTitle___3Ughe{color:rgba(0,0,0,.65)}.styles-module__LeverTitle___3Ughe strong{color:rgba(0,0,0,.65)}.styles-module__ResultsList___ku5v2{margin-top:24px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Dashboard/OpeningContent/ActionContent/QuizQuestionsLever/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CACA,+BACE,UAAA,CAAA,aAAA,CAEJ,mCACE,qBAAA,CACA,0CACE,qBAAA,CAEJ,oCACE,eAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin-top: 18px; }\n  .Root p {\n    float: initial; }\n\n.LeverTitle {\n  color: rgba(0, 0, 0, 0.65); }\n  .LeverTitle strong {\n    color: rgba(0, 0, 0, 0.65); }\n\n.ResultsList {\n  margin-top: 24px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2_BW2",
	"LeverTitle": "styles-module__LeverTitle___3Ughe",
	"ResultsList": "styles-module__ResultsList___ku5v2"
};
module.exports = exports;
