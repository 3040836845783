// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___aKpvO{display:flex;flex-direction:row;justify-content:flex-end;width:100%}.styles-module__SortOpener___2bdIV{display:flex;flex-direction:row;justify-content:flex-end;margin:24px 0}.styles-module__SortOpener___2bdIV p{font-size:14px;margin-right:8px}.styles-module__SortOpener___2bdIV svg{height:22px;margin-top:2px;transform:rotate(270deg);width:16px}.styles-module__SortOpener___2bdIV:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/sorter/SortOptionsOpener/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA,CAEF,mCACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,aAAA,CACA,qCACE,cAAA,CACA,gBAAA,CACF,uCACE,WAAA,CACA,cAAA,CACA,wBAAA,CACA,UAAA,CACF,yCACE,cAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  width: 100%; }\n\n.SortOpener {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin: 24px 0; }\n  .SortOpener p {\n    font-size: 14px;\n    margin-right: 8px; }\n  .SortOpener svg {\n    height: 22px;\n    margin-top: 2px;\n    transform: rotate(270deg);\n    width: 16px; }\n  .SortOpener:hover {\n    cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___aKpvO",
	"SortOpener": "styles-module__SortOpener___2bdIV"
};
module.exports = exports;
