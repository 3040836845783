// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Container___YnKhX{left:calc((100% - 248px)/2);width:200px}.styles-module__Buttons___3CpCa{margin-top:24px}.styles-module__Para___3P4dx{color:rgba(0,0,0,.65);margin-top:18px}.styles-module__HorizontalLine___3EnEr div{margin-left:-24px;width:calc(100% + 48px)}.styles-module__CancelButton___1Ty0f{margin-bottom:-20px;margin-left:-12px;padding:18px 0;width:calc(100% + 24px)}.styles-module__CancelButton___1Ty0f:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/modals/Confirmation/styles.module.scss"],"names":[],"mappings":"AAAA,kCACE,2BAAA,CACA,WAAA,CAEF,gCACE,eAAA,CAEF,6BACE,qBAAA,CACA,eAAA,CAEF,2CACE,iBAAA,CACA,uBAAA,CAEF,qCACE,mBAAA,CACA,iBAAA,CACA,cAAA,CACA,uBAAA,CAEF,2CACE,cAAA","file":"styles.module.scss","sourcesContent":[".Container {\n  left: calc(calc(100% - 248px) / 2);\n  width: 200px; }\n\n.Buttons {\n  margin-top: 24px; }\n\n.Para {\n  color: rgba(0, 0, 0, 0.65);\n  margin-top: 18px; }\n\n.HorizontalLine div {\n  margin-left: -24px;\n  width: calc(100% + 48px); }\n\n.CancelButton {\n  margin-bottom: -20px;\n  margin-left: -12px;\n  padding: 18px 0;\n  width: calc(100% + 24px); }\n\n.CancelButton:hover {\n  cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"Container": "styles-module__Container___YnKhX",
	"Buttons": "styles-module__Buttons___3CpCa",
	"Para": "styles-module__Para___3P4dx",
	"HorizontalLine": "styles-module__HorizontalLine___3EnEr",
	"CancelButton": "styles-module__CancelButton___1Ty0f"
};
module.exports = exports;
