// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1EMZ9{box-sizing:border-box;padding:16px}@media screen and (min-width: 568px){.styles-module__Root___1EMZ9{margin:79px auto 0;padding:16px 0;width:464px}}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/NotFound/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,qBAAA,CACA,YAAA,CACA,qCACE,6BACE,kBAAA,CACA,cAAA,CACA,WAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  box-sizing: border-box;\n  padding: 16px; }\n  @media screen and (min-width: 568px) {\n    .Root {\n      margin: 79px auto 0;\n      padding: 16px 0;\n      width: 464px; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1EMZ9"
};
module.exports = exports;
