// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___zHmSY.styles-module__hasThreeBackticks___Gx7hS>div{overflow-x:scroll}.styles-module__Image___3yMYs{border-radius:6px;margin-top:12px;width:100%}.styles-module__AudioContainer___12hsa{display:flex;flex-direction:row;justify-content:center}.styles-module__Audio___jKPbQ{margin-top:12px}.styles-module__LineBreak___2b7uw{white-space:pre-wrap}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/FormattedQuestionContent/styles.module.scss"],"names":[],"mappings":"AAAA,0EACE,iBAAA,CAEF,8BACE,iBAAA,CACA,eAAA,CACA,UAAA,CAEF,uCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEF,8BACE,eAAA,CAEF,kCACE,oBAAA","file":"styles.module.scss","sourcesContent":[".Root.hasThreeBackticks > div {\n  overflow-x: scroll; }\n\n.Image {\n  border-radius: 6px;\n  margin-top: 12px;\n  width: 100%; }\n\n.AudioContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center; }\n\n.Audio {\n  margin-top: 12px; }\n\n.LineBreak {\n  white-space: pre-wrap; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___zHmSY",
	"hasThreeBackticks": "styles-module__hasThreeBackticks___Gx7hS",
	"Image": "styles-module__Image___3yMYs",
	"AudioContainer": "styles-module__AudioContainer___12hsa",
	"Audio": "styles-module__Audio___jKPbQ",
	"LineBreak": "styles-module__LineBreak___2b7uw"
};
module.exports = exports;
