// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___-LEvR{display:flex;flex-direction:row;justify-content:flex-start;flex-wrap:wrap;grid-gap:28px;gap:28px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Stats/Charts/TextStats/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,cAAA,CACA,aAAA,CAAA,QAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: 28px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___-LEvR"
};
module.exports = exports;
