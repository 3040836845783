// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (max-width: 980px){.styles-module__Root___IWEuw{padding-bottom:82px}}@media screen and (min-width: 980px){.styles-module__LinksAndActions___37AuA{display:flex;flex-direction:row;justify-content:flex-start}}@media screen and (min-width: 980px){.styles-module__ParentContainer___2-yZ5{margin-left:96px;width:978px}}@media screen and (min-width: 980px){.styles-module__ChildrenContainer___1PwiB{margin:0 auto;width:464px}.styles-module__ChildrenContainer___1PwiB.styles-module__isOnAccountPlan___1KSrY{width:auto;width:initial}}.styles-module__Title___2s9Po{font-weight:600;margin-bottom:24px}@media screen and (min-width: 980px){.styles-module__Title___2s9Po{margin-bottom:36px}}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/profile/Content/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,6BACE,mBAAA,CAAA,CAEJ,qCACE,wCACE,YAAA,CACA,kBAAA,CACA,0BAAA,CAAA,CAEJ,qCACE,wCACE,gBAAA,CACA,WAAA,CAAA,CAEJ,qCACE,0CACE,aAAA,CACA,WAAA,CACA,iFACE,UAAA,CAAA,aAAA,CAAA,CAEN,8BACE,eAAA,CACA,kBAAA,CACA,qCACE,8BACE,kBAAA,CAAA","file":"styles.module.scss","sourcesContent":["@media screen and (max-width: 980px) {\n  .Root {\n    padding-bottom: 82px; } }\n\n@media screen and (min-width: 980px) {\n  .LinksAndActions {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start; } }\n\n@media screen and (min-width: 980px) {\n  .ParentContainer {\n    margin-left: 96px;\n    width: 978px; } }\n\n@media screen and (min-width: 980px) {\n  .ChildrenContainer {\n    margin: 0 auto;\n    width: 464px; }\n    .ChildrenContainer.isOnAccountPlan {\n      width: initial; } }\n\n.Title {\n  font-weight: 600;\n  margin-bottom: 24px; }\n  @media screen and (min-width: 980px) {\n    .Title {\n      margin-bottom: 36px; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___IWEuw",
	"LinksAndActions": "styles-module__LinksAndActions___37AuA",
	"ParentContainer": "styles-module__ParentContainer___2-yZ5",
	"ChildrenContainer": "styles-module__ChildrenContainer___1PwiB",
	"isOnAccountPlan": "styles-module__isOnAccountPlan___1KSrY",
	"Title": "styles-module__Title___2s9Po"
};
module.exports = exports;
