// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2r4Dy{width:100%}.styles-module__Root___2r4Dy.styles-module__defaultSpacing___3C9Ho{margin:18px 0 55px}.styles-module__Root___2r4Dy.styles-module__isCentered___1Po2S{display:flex;flex-direction:row;justify-content:center;align-items:center}.styles-module__Root___2r4Dy.styles-module__isLeft___RXQWD{float:left}.styles-module__Image___3H4D8{resize-mode:contain}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/images/logos/FaceOnly/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,UAAA,CACA,mEACE,kBAAA,CACF,+DACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACF,2DACE,UAAA,CAEJ,8BACE,mBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  width: 100%; }\n  .Root.defaultSpacing {\n    margin: 18px 0 55px; }\n  .Root.isCentered {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center; }\n  .Root.isLeft {\n    float: left; }\n\n.Image {\n  resize-mode: contain; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2r4Dy",
	"defaultSpacing": "styles-module__defaultSpacing___3C9Ho",
	"isCentered": "styles-module__isCentered___1Po2S",
	"isLeft": "styles-module__isLeft___RXQWD",
	"Image": "styles-module__Image___3H4D8"
};
module.exports = exports;
