// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (max-width: 767px){.styles-module__Root___3dR1q{padding:16px 16px 103px;background-color:#fff;box-sizing:border-box;height:100%;width:100%;height:-moz-min-content;height:min-content;min-height:calc(100vh - 66px);padding-bottom:66px}}@media screen and (max-width: 767px)and (min-width: 768px){.styles-module__Root___3dR1q{margin:0 auto;padding:16px 0 103px;width:464px}}@media screen and (min-width: 768px){.styles-module__MobileContainer___2MoSl{display:none}}@media screen and (max-width: 767px){.styles-module__DesktopContainer___2Eyvn{display:none}}div.styles-module__Card___1daN6.styles-module__CardExtraSpecificity___1VTj2.styles-module__CardExtraExtraSpecificity___1Qvo_>div{width:-moz-fit-content;width:fit-content}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/profile/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,6BACE,uBAAA,CACA,qBAAA,CACA,qBAAA,CACA,WAAA,CACA,UAAA,CACA,uBAAA,CAAA,kBAAA,CACA,6BAAA,CACA,mBAAA,CAAA,CACF,2DACE,6BACE,aAAA,CACA,oBAAA,CACA,WAAA,CAAA,CAEN,qCACE,wCACE,YAAA,CAAA,CAEJ,qCACE,yCACE,YAAA,CAAA,CAEJ,iIACE,sBAAA,CAAA,iBAAA","file":"styles.module.scss","sourcesContent":["@media screen and (max-width: 767px) {\n  .Root {\n    padding: 16px 16px 103px;\n    background-color: #ffffff;\n    box-sizing: border-box;\n    height: 100%;\n    width: 100%;\n    height: min-content;\n    min-height: calc(100vh - 66px);\n    padding-bottom: 66px; } }\n  @media screen and (max-width: 767px) and (min-width: 768px) {\n    .Root {\n      margin: 0 auto;\n      padding: 16px 0 103px;\n      width: 464px; } }\n\n@media screen and (min-width: 768px) {\n  .MobileContainer {\n    display: none; } }\n\n@media screen and (max-width: 767px) {\n  .DesktopContainer {\n    display: none; } }\n\ndiv.Card.CardExtraSpecificity.CardExtraExtraSpecificity > div {\n  width: fit-content; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3dR1q",
	"MobileContainer": "styles-module__MobileContainer___2MoSl",
	"DesktopContainer": "styles-module__DesktopContainer___2Eyvn",
	"Card": "styles-module__Card___1daN6",
	"CardExtraSpecificity": "styles-module__CardExtraSpecificity___1VTj2",
	"CardExtraExtraSpecificity": "styles-module__CardExtraExtraSpecificity___1Qvo_"
};
module.exports = exports;
