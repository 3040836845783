// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__ButtonDiv___2SnAp>div{box-shadow:0 2px 4px 1px rgba(38,35,34,.08);padding:10px 21px;width:100%}.styles-module__ButtonDiv___2SnAp.styles-module__isActive___3HJcW>div{box-shadow:none}.styles-module__GoogleLetterIconContainer___3jubt{height:20px;width:20px}.styles-module__GoogleLetterIconContainer___3jubt svg{height:20px;width:20px}.styles-module__GoogleLetterIconContainer___3jubt svg path{fill:#fff}.styles-module__Para___3Qya_{color:#fff;font-weight:bold;line-height:16px;margin:2px 0 0 18px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/forms/GoogleAuth/styles.module.scss"],"names":[],"mappings":"AAAA,sCACE,2CAAA,CACA,iBAAA,CACA,UAAA,CAEF,sEACE,eAAA,CAEF,kDACE,WAAA,CACA,UAAA,CACA,sDACE,WAAA,CACA,UAAA,CACA,2DACE,SAAA,CAEN,6BACE,UAAA,CACA,gBAAA,CACA,gBAAA,CACA,mBAAA","file":"styles.module.scss","sourcesContent":[".ButtonDiv > div {\n  box-shadow: 0 2px 4px 1px rgba(38, 35, 34, 0.08);\n  padding: 10px 21px;\n  width: 100%; }\n\n.ButtonDiv.isActive > div {\n  box-shadow: none; }\n\n.GoogleLetterIconContainer {\n  height: 20px;\n  width: 20px; }\n  .GoogleLetterIconContainer svg {\n    height: 20px;\n    width: 20px; }\n    .GoogleLetterIconContainer svg path {\n      fill: #ffffff; }\n\n.Para {\n  color: #ffffff;\n  font-weight: bold;\n  line-height: 16px;\n  margin: 2px 0 0 18px; }\n"]}]);
// Exports
exports.locals = {
	"ButtonDiv": "styles-module__ButtonDiv___2SnAp",
	"isActive": "styles-module__isActive___3HJcW",
	"GoogleLetterIconContainer": "styles-module__GoogleLetterIconContainer___3jubt",
	"Para": "styles-module__Para___3Qya_"
};
module.exports = exports;
