// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Para___3BKD4{word-wrap:break-word}.styles-module__ContactUsPara___3OC6_{margin-top:12px}.styles-module__ContactUsPara___3OC6_ a{color:#2071aa;cursor:pointer;text-decoration:none}.styles-module__ContactUsPara___3OC6_ a:hover{cursor:pointer}.styles-module__ContactUsPara___3OC6_ a *:hover{cursor:pointer}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/authentication/SignInOtpConfirmation/EmailIssueAlert/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,oBAAA,CAEF,sCACE,eAAA,CACA,wCACE,aAAA,CACA,cAAA,CACA,oBAAA,CACA,8CACE,cAAA,CACF,gDACE,cAAA","file":"styles.module.scss","sourcesContent":[".Para {\n  word-wrap: break-word; }\n\n.ContactUsPara {\n  margin-top: 12px; }\n  .ContactUsPara a {\n    color: #2071aa;\n    cursor: pointer;\n    text-decoration: none; }\n    .ContactUsPara a:hover {\n      cursor: pointer; }\n    .ContactUsPara a *:hover {\n      cursor: pointer; }\n"]}]);
// Exports
exports.locals = {
	"Para": "styles-module__Para___3BKD4",
	"ContactUsPara": "styles-module__ContactUsPara___3OC6_"
};
module.exports = exports;
