// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___wOVPs{display:flex;flex-direction:row;justify-content:center;flex-wrap:wrap;grid-gap:32px;gap:32px;margin:0 auto}@media screen and (max-width: 980px){.styles-module__Root___wOVPs{flex-direction:column;margin:0 auto;width:-moz-fit-content;width:fit-content}}.styles-module__SubTitle___1ECPp{text-align:center}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/profile/AccountPlan/Plans/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,aAAA,CAAA,QAAA,CACA,aAAA,CACA,qCACE,6BACE,qBAAA,CACA,aAAA,CACA,sBAAA,CAAA,iBAAA,CAAA,CAEN,iCACE,iBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 32px;\n  margin: 0 auto; }\n  @media screen and (max-width: 980px) {\n    .Root {\n      flex-direction: column;\n      margin: 0 auto;\n      width: fit-content; } }\n\n.SubTitle {\n  text-align: center; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___wOVPs",
	"SubTitle": "styles-module__SubTitle___1ECPp"
};
module.exports = exports;
