// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Link___3_bVU{color:#2071aa;cursor:pointer;text-decoration:none;font-weight:600}.styles-module__Link___3_bVU:hover{cursor:pointer}.styles-module__Link___3_bVU *:hover{cursor:pointer}.styles-module__LetUsKnowParagraphContent___1nxWl{margin-bottom:24px;text-align:left}.styles-module__LetUsKnowParagraphContent___1nxWl:first-of-type{margin-bottom:12px;margin-top:12px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/profile/ContactUs/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,aAAA,CACA,cAAA,CACA,oBAAA,CACA,eAAA,CACA,mCACE,cAAA,CACF,qCACE,cAAA,CAEJ,kDACE,kBAAA,CACA,eAAA,CACA,gEACE,kBAAA,CACA,eAAA","file":"styles.module.scss","sourcesContent":[".Link {\n  color: #2071aa;\n  cursor: pointer;\n  text-decoration: none;\n  font-weight: 600; }\n  .Link:hover {\n    cursor: pointer; }\n  .Link *:hover {\n    cursor: pointer; }\n\n.LetUsKnowParagraphContent {\n  margin-bottom: 24px;\n  text-align: left; }\n  .LetUsKnowParagraphContent:first-of-type {\n    margin-bottom: 12px;\n    margin-top: 12px; }\n"]}]);
// Exports
exports.locals = {
	"Link": "styles-module__Link___3_bVU",
	"LetUsKnowParagraphContent": "styles-module__LetUsKnowParagraphContent___1nxWl"
};
module.exports = exports;
