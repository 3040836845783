// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__ImageContainer___1hB5N{display:flex;flex-direction:row;justify-content:center;margin:0 auto}.styles-module__ImageContainer___1hB5N img{max-width:150px}.styles-module__Para___30izG{margin-top:12px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/profile/InstallApp/styles.module.scss"],"names":[],"mappings":"AAAA,uCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,aAAA,CACA,2CACE,eAAA,CAEJ,6BACE,eAAA","file":"styles.module.scss","sourcesContent":[".ImageContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin: 0 auto; }\n  .ImageContainer img {\n    max-width: 150px; }\n\n.Para {\n  margin-top: 12px; }\n"]}]);
// Exports
exports.locals = {
	"ImageContainer": "styles-module__ImageContainer___1hB5N",
	"Para": "styles-module__Para___30izG"
};
module.exports = exports;
