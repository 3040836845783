// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__LetUsKnowParagraphContent___1WZJ8{margin-bottom:24px;text-align:left}.styles-module__LetUsKnowParagraphContent___1WZJ8:first-of-type{margin-bottom:12px;margin-top:12px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Dashboard/OpeningContent/LetUsKnowModal/styles.module.scss"],"names":[],"mappings":"AAAA,kDACE,kBAAA,CACA,eAAA,CACA,gEACE,kBAAA,CACA,eAAA","file":"styles.module.scss","sourcesContent":[".LetUsKnowParagraphContent {\n  margin-bottom: 24px;\n  text-align: left; }\n  .LetUsKnowParagraphContent:first-of-type {\n    margin-bottom: 12px;\n    margin-top: 12px; }\n"]}]);
// Exports
exports.locals = {
	"LetUsKnowParagraphContent": "styles-module__LetUsKnowParagraphContent___1WZJ8"
};
module.exports = exports;
