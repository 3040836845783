// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2xrPT{display:flex;flex-direction:row;justify-content:flex-start}.styles-module__Root___2xrPT:hover,.styles-module__Root___2xrPT:focus{cursor:pointer}.styles-module__Root___2xrPT p{color:rgba(0,0,0,.65);line-height:16px;margin-left:2px}.styles-module__Root___2xrPT svg path{fill:rgba(0,0,0,.65)}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/profile/SignOutButton/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,sEACE,cAAA,CACF,+BACE,qBAAA,CACA,gBAAA,CACA,eAAA,CACF,sCACE,oBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start; }\n  .Root:hover, .Root:focus {\n    cursor: pointer; }\n  .Root p {\n    color: rgba(0, 0, 0, 0.65);\n    line-height: 16px;\n    margin-left: 2px; }\n  .Root svg path {\n    fill: rgba(0, 0, 0, 0.65); }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2xrPT"
};
module.exports = exports;
