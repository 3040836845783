// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3ZREx{margin-top:24px}.styles-module__Container___1T1SP{margin-top:12px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/forms/Question/Options/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CAEF,kCACE,eAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  margin-top: 24px; }\n\n.Container {\n  margin-top: 12px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3ZREx",
	"Container": "styles-module__Container___1T1SP"
};
module.exports = exports;
