// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1E2s3{position:relative}.styles-module__SearchIcon___3GQFk{left:10px;position:absolute;top:8px}.styles-module__SearchIcon___3GQFk svg path{fill:rgba(0,0,0,.65)}.styles-module__Input___2hezT{background-color:#fff;border-radius:100px;box-shadow:0 2px 4px 1px rgba(0,0,0,.12);box-sizing:border-box;line-height:31px;overflow:hidden;padding:2px 12px 2px 38px;text-overflow:ellipsis;white-space:nowrap;width:100%}.styles-module__Input___2hezT:focus{box-shadow:0 2px 4px 1px rgba(0,0,0,.12);outline:none}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/fields/SearchField/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CAEF,mCACE,SAAA,CACA,iBAAA,CACA,OAAA,CACA,4CACE,oBAAA,CAEJ,8BACE,qBAAA,CACA,mBAAA,CACA,wCAAA,CACA,qBAAA,CACA,gBAAA,CACA,eAAA,CACA,yBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,oCACE,wCAAA,CACA,YAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  position: relative; }\n\n.SearchIcon {\n  left: 10px;\n  position: absolute;\n  top: 8px; }\n  .SearchIcon svg path {\n    fill: rgba(0, 0, 0, 0.65); }\n\n.Input {\n  background-color: #ffffff;\n  border-radius: 100px;\n  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.12);\n  box-sizing: border-box;\n  line-height: 31px;\n  overflow: hidden;\n  padding: 2px 12px 2px 38px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 100%; }\n  .Input:focus {\n    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.12);\n    outline: none; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1E2s3",
	"SearchIcon": "styles-module__SearchIcon___3GQFk",
	"Input": "styles-module__Input___2hezT"
};
module.exports = exports;
