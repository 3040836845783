// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2DIep{font-size:14px;line-height:normal;line-height:initial;opacity:.8}.styles-module__Root___2DIep span,.styles-module__Root___2DIep strong,.styles-module__Root___2DIep ul li,.styles-module__Root___2DIep a{font-size:14px;line-height:normal;line-height:initial}.styles-module__Root___2DIep a{color:#2071aa}.styles-module__Root___2DIep strong{font-weight:bold}.styles-module__Root___2DIep ul{padding-left:22px}.styles-module__Root___2DIep ul li{list-style:disc}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Stats/Charts/InfoText/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAA,CACA,kBAAA,CAAA,mBAAA,CACA,UAAA,CACA,wIAIE,cAAA,CACA,kBAAA,CAAA,mBAAA,CACF,+BACE,aAAA,CACF,oCACE,gBAAA,CACF,gCACE,iBAAA,CACA,mCACE,eAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  font-size: 14px;\n  line-height: initial;\n  opacity: 0.8; }\n  .Root span,\n  .Root strong,\n  .Root ul li,\n  .Root a {\n    font-size: 14px;\n    line-height: initial; }\n  .Root a {\n    color: #2071aa; }\n  .Root strong {\n    font-weight: bold; }\n  .Root ul {\n    padding-left: 22px; }\n    .Root ul li {\n      list-style: disc; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2DIep"
};
module.exports = exports;
