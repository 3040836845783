// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___8SWw8{display:flex;flex-direction:row;justify-content:center;margin:24px auto}.styles-module__Root___8SWw8.styles-module__noMargins___bWJZp{margin:0}.styles-module__Root___8SWw8 p{color:rgba(0,0,0,.65);margin:0 12px}.styles-module__OrContainerLine___3MBxM{border-bottom:1px solid #828282;height:12px;width:60px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/OrLinesContainer/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,8DACE,QAAA,CACF,+BACE,qBAAA,CACA,aAAA,CAEJ,wCACE,+BAAA,CACA,WAAA,CACA,UAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin: 24px auto; }\n  .Root.noMargins {\n    margin: 0; }\n  .Root p {\n    color: rgba(0, 0, 0, 0.65);\n    margin: 0 12px; }\n\n.OrContainerLine {\n  border-bottom: 1px solid #828282;\n  height: 12px;\n  width: 60px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___8SWw8",
	"noMargins": "styles-module__noMargins___bWJZp",
	"OrContainerLine": "styles-module__OrContainerLine___3MBxM"
};
module.exports = exports;
