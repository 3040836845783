// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2DwsO{background:#e5ebea;border:1px solid #d9d8d7;border-radius:6px;padding:36px}.styles-module__ComingSoonTitle___1ciZh{font-weight:600}.styles-module__ComingSoonPara___3UY3R{color:rgba(0,0,0,.65)}.styles-module__ComingSoonTitle___1ciZh,.styles-module__ComingSoonPara___3UY3R{text-align:center}.styles-module__IconContainer___2gfS6{height:44px;margin:0 auto 18px;width:44px}.styles-module__IconContainer___2gfS6 ellipse{fill:#0a3959}.styles-module__IconContainer___2gfS6 path{fill:#eaf4fb}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/StatsPlaceholder/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CAEF,wCACE,eAAA,CAEF,uCACE,qBAAA,CAEF,+EAEE,iBAAA,CAEF,sCACE,WAAA,CACA,kBAAA,CACA,UAAA,CACA,8CACE,YAAA,CACF,2CACE,YAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  background: #e5ebea;\n  border: 1px solid #d9d8d7;\n  border-radius: 6px;\n  padding: 36px; }\n\n.ComingSoonTitle {\n  font-weight: 600; }\n\n.ComingSoonPara {\n  color: rgba(0, 0, 0, 0.65); }\n\n.ComingSoonTitle,\n.ComingSoonPara {\n  text-align: center; }\n\n.IconContainer {\n  height: 44px;\n  margin: 0 auto 18px;\n  width: 44px; }\n  .IconContainer ellipse {\n    fill: #0a3959; }\n  .IconContainer path {\n    fill: #eaf4fb; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2DwsO",
	"ComingSoonTitle": "styles-module__ComingSoonTitle___1ciZh",
	"ComingSoonPara": "styles-module__ComingSoonPara___3UY3R",
	"IconContainer": "styles-module__IconContainer___2gfS6"
};
module.exports = exports;
