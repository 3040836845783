// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__SliderContainer___1PH8R{display:flex;flex-direction:row;justify-content:space-between;grid-gap:12px;gap:12px}@media screen and (max-width: 468px){.styles-module__HorizontalLine___3nzfx{display:none}}.styles-module__HorizontalLine___3nzfx:last-of-type{display:none}.styles-module__SubHint___2sNRM{color:rgba(0,0,0,.65);margin-top:-10px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/profile/Notifications/Notification/styles.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,aAAA,CAAA,QAAA,CAEF,qCACE,uCACE,YAAA,CAAA,CAEJ,oDACE,YAAA,CAEF,gCACE,qBAAA,CACA,gBAAA","file":"styles.module.scss","sourcesContent":[".SliderContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: 12px; }\n\n@media screen and (max-width: 468px) {\n  .HorizontalLine {\n    display: none; } }\n\n.HorizontalLine:last-of-type {\n  display: none; }\n\n.SubHint {\n  color: rgba(0, 0, 0, 0.65);\n  margin-top: -10px; }\n"]}]);
// Exports
exports.locals = {
	"SliderContainer": "styles-module__SliderContainer___1PH8R",
	"HorizontalLine": "styles-module__HorizontalLine___3nzfx",
	"SubHint": "styles-module__SubHint___2sNRM"
};
module.exports = exports;
