// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___2Z5s7{height:20px;width:20px}.styles-module__Root___2Z5s7 g path{fill:#2071aa}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/icons/Search/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,WAAA,CACA,UAAA,CACA,oCACE,YAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  height: 20px;\n  width: 20px; }\n  .Root g path {\n    fill: #2071aa; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___2Z5s7"
};
module.exports = exports;
