// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__LeverTitle___ZatR3{color:#828282}.styles-module__LeverTitle___ZatR3 strong{color:#828282}.styles-module__Root___TCHvR{padding-top:18px}.styles-module__Para___3ZzUm:not(:first-of-type){margin-top:18px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/quiz/Explanation/HowTheyWork/styles.module.scss"],"names":[],"mappings":"AAAA,mCACE,aAAA,CACA,0CACE,aAAA,CAEJ,6BACE,gBAAA,CAEF,iDACE,eAAA","file":"styles.module.scss","sourcesContent":[".LeverTitle {\n  color: #828282; }\n  .LeverTitle strong {\n    color: #828282; }\n\n.Root {\n  padding-top: 18px; }\n\n.Para:not(:first-of-type) {\n  margin-top: 18px; }\n"]}]);
// Exports
exports.locals = {
	"LeverTitle": "styles-module__LeverTitle___ZatR3",
	"Root": "styles-module__Root___TCHvR",
	"Para": "styles-module__Para___3ZzUm"
};
module.exports = exports;
