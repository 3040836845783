// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___L5l-v.styles-module__isActive___18IFQ{display:none}.styles-module__SecondLink___2VvDe{margin-top:6px}@media screen and (min-width: 768px){.styles-module__SecondLink___2VvDe{display:none}}.styles-module__ButtonLink___Gskby.styles-module__isTertiary___1m7zE a{margin:18px 0 0;width:auto;width:initial}.styles-module__ButtonLink___Gskby.styles-module__shouldReallyDrawFocus___11tVC a{margin:36px auto 24px}.styles-module__ButtonLink___Gskby.styles-module__shouldReallyDrawFocus___11tVC~.styles-module__SecondLink___2VvDe{margin-top:-18px}@media screen and (min-width: 568px){.styles-module__ButtonLink___Gskby a{margin:36px auto 18px;width:auto;width:initial}.styles-module__ButtonLink___Gskby.styles-module__shouldReallyDrawFocus___11tVC a{margin:45px auto 36px}}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Dashboard/OpeningContent/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,6DACE,YAAA,CAEF,mCACE,cAAA,CACA,qCACE,mCACE,YAAA,CAAA,CAEN,uEACE,eAAA,CACA,UAAA,CAAA,aAAA,CAEF,kFACE,qBAAA,CAEF,mHACE,gBAAA,CAEF,qCACE,qCACE,qBAAA,CACA,UAAA,CAAA,aAAA,CACF,kFACE,qBAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root.isActive {\n  display: none; }\n\n.SecondLink {\n  margin-top: 6px; }\n  @media screen and (min-width: 768px) {\n    .SecondLink {\n      display: none; } }\n\n.ButtonLink.isTertiary a {\n  margin: 18px 0 0;\n  width: initial; }\n\n.ButtonLink.shouldReallyDrawFocus a {\n  margin: 36px auto 24px; }\n\n.ButtonLink.shouldReallyDrawFocus ~ .SecondLink {\n  margin-top: -18px; }\n\n@media screen and (min-width: 568px) {\n  .ButtonLink a {\n    margin: 36px auto 18px;\n    width: initial; }\n  .ButtonLink.shouldReallyDrawFocus a {\n    margin: 45px auto 36px; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___L5l-v",
	"isActive": "styles-module__isActive___18IFQ",
	"SecondLink": "styles-module__SecondLink___2VvDe",
	"ButtonLink": "styles-module__ButtonLink___Gskby",
	"isTertiary": "styles-module__isTertiary___1m7zE",
	"shouldReallyDrawFocus": "styles-module__shouldReallyDrawFocus___11tVC"
};
module.exports = exports;
