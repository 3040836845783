// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1LtVc{border-radius:6px;margin-top:6px;width:100%}@media screen and (min-width: 980px){.styles-module__Root___1LtVc{margin-top:0}}.styles-module__Button___3yhfe{margin:36px 0 36px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/forms/StudySet/ShowMoreContainer/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,cAAA,CACA,UAAA,CACA,qCACE,6BACE,YAAA,CAAA,CAEN,+BACE,kBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  border-radius: 6px;\n  margin-top: 6px;\n  width: 100%; }\n  @media screen and (min-width: 980px) {\n    .Root {\n      margin-top: 0; } }\n\n.Button {\n  margin: 36px 0 36px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1LtVc",
	"Button": "styles-module__Button___3yhfe"
};
module.exports = exports;
