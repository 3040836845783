// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___3uOFf{display:flex;flex-direction:column;grid-gap:16px;gap:16px;padding:16px 20px 20px}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/Stats/Charts/AvgFutureRetention/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,aAAA,CAAA,QAAA,CACA,sBAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 16px 20px 20px; }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___3uOFf"
};
module.exports = exports;
