// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "@media screen and (max-width: 979px){.styles-module__Header___3M4Of{display:none}}@media screen and (min-width: 980px){.styles-module__Footer___38x2B{display:none}}.styles-module__HomeIcon___3jz9B svg{margin-bottom:-8px;margin-top:-1px;width:26px}.styles-module__HomeIcon___3jz9B svg path{fill:rgba(0,0,0,.65)}.styles-module__ProfileIcon___1q5rD svg{margin-bottom:-5.5px;width:22px}.styles-module__ProfileIcon___1q5rD svg circle{fill:rgba(0,0,0,.65)}.styles-module__ProfileIcon___1q5rD svg path{fill:rgba(0,0,0,.65)}.styles-module__LibraryIcon___3DIXO svg{margin-bottom:-11px;margin-top:-3px;width:30px}.styles-module__LibraryIcon___3DIXO svg path{fill:rgba(0,0,0,.65)}.styles-module__PssFooterItem___cb0VG{width:30%}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/navigation/SiteWideLinks/Unauthed/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,+BACE,YAAA,CAAA,CAEJ,qCACE,+BACE,YAAA,CAAA,CAEJ,qCACE,kBAAA,CACA,eAAA,CACA,UAAA,CACA,0CACE,oBAAA,CAEJ,wCACE,oBAAA,CACA,UAAA,CACA,+CACE,oBAAA,CACF,6CACE,oBAAA,CAEJ,wCACE,mBAAA,CACA,eAAA,CACA,UAAA,CACA,6CACE,oBAAA,CAEJ,sCACE,SAAA","file":"styles.module.scss","sourcesContent":["@media screen and (max-width: 979px) {\n  .Header {\n    display: none; } }\n\n@media screen and (min-width: 980px) {\n  .Footer {\n    display: none; } }\n\n.HomeIcon svg {\n  margin-bottom: -8px;\n  margin-top: -1px;\n  width: 26px; }\n  .HomeIcon svg path {\n    fill: rgba(0, 0, 0, 0.65); }\n\n.ProfileIcon svg {\n  margin-bottom: -5.5px;\n  width: 22px; }\n  .ProfileIcon svg circle {\n    fill: rgba(0, 0, 0, 0.65); }\n  .ProfileIcon svg path {\n    fill: rgba(0, 0, 0, 0.65); }\n\n.LibraryIcon svg {\n  margin-bottom: -11px;\n  margin-top: -3px;\n  width: 30px; }\n  .LibraryIcon svg path {\n    fill: rgba(0, 0, 0, 0.65); }\n\n.PssFooterItem {\n  width: 30%; }\n"]}]);
// Exports
exports.locals = {
	"Header": "styles-module__Header___3M4Of",
	"Footer": "styles-module__Footer___38x2B",
	"HomeIcon": "styles-module__HomeIcon___3jz9B",
	"ProfileIcon": "styles-module__ProfileIcon___1q5rD",
	"LibraryIcon": "styles-module__LibraryIcon___3DIXO",
	"PssFooterItem": "styles-module__PssFooterItem___cb0VG"
};
module.exports = exports;
