// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".styles-module__Root___1EwvK{box-sizing:border-box;height:calc(100% - 65px);left:0;opacity:1;overflow:scroll;padding-top:120px;position:absolute;top:0;width:100%;z-index:3}@media screen and (min-width: 568px){.styles-module__Root___1EwvK{background-color:#fff}}", "",{"version":3,"sources":["/tmp/build_77369a77/app/javascript/src/components/quiz/Congratulations/Transition/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,qBAAA,CACA,wBAAA,CACA,MAAA,CACA,SAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,KAAA,CACA,UAAA,CACA,SAAA,CACA,qCACE,6BACE,qBAAA,CAAA","file":"styles.module.scss","sourcesContent":[".Root {\n  box-sizing: border-box;\n  height: calc(100% - 65px);\n  left: 0;\n  opacity: 1;\n  overflow: scroll;\n  padding-top: 120px;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 3; }\n  @media screen and (min-width: 568px) {\n    .Root {\n      background-color: #ffffff; } }\n"]}]);
// Exports
exports.locals = {
	"Root": "styles-module__Root___1EwvK"
};
module.exports = exports;
